import * as React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { PDFDownloadLink } from '@react-pdf/renderer';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
function DownloadModal(props) {
    const { onClose, open, pdfData, pdfProfileName, docx, drive } = props;

    const handleListItemClick = (value) => {
        if (value === 'DOCX') {
            docx();
        } else if (value === 'Google Drive') {
            drive();
        }
        onClose();
    };

    return (
        <Dialog onClose={onClose} open={open} PaperProps={{
            sx: {
                backgroundColor: 'black',//, // Change this color to any custom color
                border: '3px solid',
                borderColor: '#380093',
                color: 'white',  // Change the text color if needed
            }
        }} >
            <DialogTitle>Select File Type:</DialogTitle>
            <List sx={{ pt: 0 }}>
                <ListItem className="border-b border-black bg-[#380093]" disableGutters >
                    {/* Directly render the PDFDownloadLink without click handler */}
                    <ListItemButton>
                        <PDFDownloadLink
                            document={pdfData}
                            fileName={`${pdfProfileName}.pdf`}
                            className="no-underline text-inherit w-full"
                        >
                            <ListItemText primary="PDF" />
                        </PDFDownloadLink>
                    </ListItemButton>
                </ListItem>
                <ListItem className="border-b border-black bg-[#380093]" disableGutters>
                    <ListItemButton onClick={() => handleListItemClick('DOCX')}>
                        <ListItemText primary="DOCX" />
                    </ListItemButton>
                </ListItem>
                <ListItem className="border-b border-black bg-[#380093]" disableGutters>
                    <ListItemButton onClick={() => handleListItemClick('Google Drive')}>
                        <ListItemText primary="Google Drive" />
                    </ListItemButton>
                </ListItem>
                <ListItem className="border-b border-black bg-[#380093]" disableGutters>
                    <ListItemButton
                        autoFocus
                        onClick={onClose}
                    >
                        <ListItemText primary="Cancel" />
                    </ListItemButton>
                </ListItem>
            </List>
        </Dialog>
    );
}

DownloadModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    pdfData: PropTypes.object.isRequired,
    pdfProfileName: PropTypes.string.isRequired,
    docx: PropTypes.func.isRequired,
    drive: PropTypes.func.isRequired,
};

export default function DownloadModalOptions({ pdfData, pdfProfileName, docx, drive }) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <button onClick={handleClickOpen}
            >
                Download
                <FileDownloadIcon className='ml-[8px]' />
            </button>
            <DownloadModal
                open={open}
                onClose={handleClose}
                pdfData={pdfData}
                pdfProfileName={pdfProfileName}
                docx={docx}
                drive={drive}
            />
        </div>
    );
}
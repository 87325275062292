import React, { useEffect, useState } from "react";
import WrapperModalPlan from "../../Components/WrapperPlan";
import ProposalCard from "../../Components/ProposalCard";
import { signOut } from "firebase/auth";
import { auth, } from "../../utils/firebase";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button, Typography } from "@mui/material";
import { useDispatch } from 'react-redux';
import { addClient } from '../../Redux/slices/clientSlice';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CreateNewUserModal from "../../Components/CreateNewUserModal";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import "./dashboard.css";
import { useSelector } from "react-redux";
import NavBar from "../../Components/NavBar";
import Collaborators from "../../Components/Collaborators";
import useMediaQuery from '@mui/material/useMediaQuery';
function Main(props) {
  const isSmallScreen = useMediaQuery('(max-width:640px)');
  const isMediumScreen = useMediaQuery('(max-width:1024px)');
  const isLargeScreen = useMediaQuery('(min-width:1025px) and (max-width:1440px)');
  const isXLargeScreen = useMediaQuery('(min-width:1441px)');
  const clientsStore = useSelector(state => state.clients);
  const [data, setData] = useState([]);
  const [clients, setClients] = useState({});
  const [clientNames, setClientNames] = useState([]);
  const location = useLocation();
  const [currentClient, setCurrentClient] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const baseurl = process.env.REACT_APP_API_URL;
  //MUI styles 

  const AddClientButton = styled(Button)({
    height: "45px",
    color: "white",
    width: "97%",
    fontSize: "18px",
    backgroundColor: "#380093",
    border: "solid 3px #5406D2",
    margin: "5px 0",
  });

  const ViewAllClientButton = styled(Button)({
    backgroundColor: "#784DBD",
    border: "2px solid #DBC5FF",
    width: "97%",
    height: "45px",
    color: "white",
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        navigate("/login");
      } else {
        const uid = user.uid;
        await readData(uid, "");
      }
    });

    return () => unsubscribe();
  }, [navigate, clientsStore]); 

  useEffect(() => {
    if (location.state?.currentClient) {
      setCurrentClient(location.state.currentClient)
    }
    if(location.state?.organization_name) {
      setClientNames(location.state.organization_name)
    }
  }, [location.state])

  useEffect(() => {
    const lastClient = localStorage.getItem('LastClient');
    const clientIds = Object.keys(clientsStore);

    // If there is at least one client
    if (clientIds.length > 1) {
      // If the user has only one client
      if (clientIds.length === 1) {
        setCurrentClient({ ...clientsStore[clientIds[0]][Object.keys(clientsStore[clientIds[0]])[0]], client_id: Object.keys(clientsStore[clientIds[0]])[0] });
      }
      // If the user has more than one client
      else {
        // Check if the lastClient exists in the clientsStore
        const foundLastClient = clientIds.some(
          clientId => clientsStore[clientId][lastClient]
        );

        if (lastClient && foundLastClient) {
          setCurrentClient({ ...clientsStore[clientIds[1]][lastClient], client_id: lastClient });
        } else {
          // Fallback to setting the first available client if no last client is found
          setCurrentClient({ ...clientsStore[clientIds[1]][Object.keys(clientsStore[clientIds[1]])[0]], client_id: Object.keys(clientsStore[clientIds[1]])[0] });
        }
      }
    }
  }, [clientsStore]);
  // async function fetchClientNames(uid) {
  //   try {
  //     const response = await fetch(`${baseurl}/get-client-names`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ uid: uid }),
  //     });

  //     const result = await response.json();
  //     if (result.status === 'success') {
  //       const organizationNames = result.uid.organization_names;
  //       console.log('Extracted organization names:', organizationNames);  
  //       setClientNames(organizationNames)
  //     } else {
  //       console.error('Error fetching client details:', result.message);
  //     }

  //   } catch (error) {
  //     console.error('Error making API call:', error);
  //   }
  // }
  
  async function readData(uid, client_id) {
    let needed_client_id = client_id
    try {
      const response = await fetch(`${baseurl}/get-user-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: uid }),
      });

      const sortedData = await response.json();
      const clientsData = [];
      const clientID = Object.keys(sortedData.sub_collections)[0];
      // const grantForms = sortedData.sub_collections[clientID];  
      if (currentClient === clientID && currentClient != "" || clientID === needed_client_id) {
        if (sortedData.sub_collections) {
          for (const [clientId, clientArray] of Object.entries(sortedData.sub_collections)) {
            clientArray.forEach(client => {
              clientsData.push({ id: clientId, ...client });
            });
          }
        }
        setData(clientsData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleClientClick = async (client_id) => {
    if (client_id != currentClient) {
      if (currentClient == "") {
        setCurrentClient(client_id);
        // try {
        //   const user = auth.currentUser;
        //   if (!user) {
        //     navigate("/login");
        //   } else {
        //     const uid = user.uid;
        //     await readData(uid, "");
        //     // await fetchClients(uid);
        //   }
        // } catch (error) {
        //   console.error("Error handling client click:", error);
        // }
      }
      else {
        setCurrentClient(client_id)
        const user = auth.currentUser;
        if (!user) {
          navigate("/login");
        } else {
          const uid = user.uid;
          setData([])
        }
      }
    }
  };


  const handleNew = () => {
    if (currentClient === "") {
      alert("Select/click a client to add the new proposal.");
    } else {
      navigate("/new-proposal-dashboard", { state: { currentClient } });
    }
  };

  const handleClick = (formDetails) => {
    navigate("/grant-assistant", { state: { formDetails, currentClient, from: "proposal-dashboard" } });
  };

  return (
    <div className="overflow-hidden max-h-screen">
      <NavBar />

      <div className="dash-2 top-12">
        <div className="w-1/5 bg-[#11002e] border-r-4 border-[#5406D2] overflow-auto flex">
          <div className="w-1/6 bg-[#250062] flex">
            {/* <Fab size="small"></Fab> */}
          </div>
          <div className="w-full flex-col space-y-12">
            <Typography
              sx={{ display: "flex", justifyContent: "center", position: "relative", top: "1.5rem" }}
              id="modal-title"
              color="white"
            >
              Client List
            </Typography>

            <AddClientButton
              type="button"
              className=" mt-32   "
              onClick={() => setOpenModal(true)}
            >
              <AddCircleOutlineIcon />
              <div className="text-[16px] pl-1 font-thin lowercase rounded-md"> <span className="uppercase">N</span>ew <span className="uppercase">C</span>lient</div>

            </AddClientButton>

            <CreateNewUserModal
              open={openModal}
              onClose={() => setOpenModal(false)}
              clients={clients}
              setClients={setClients}
            />
            {/* <Link to="/all-client-dashboard">
              <ViewAllClientButton
                type="button"
                onClick={handleOpen}
              >
                <div>View All Clients</div>
              </ViewAllClientButton>
            </Link> */}
            <div className="client-list relative bottom-[1.77rem]">
              {clientsStore && Object.keys(clientsStore).length > 0 ? (
                (() => {
                  const secondKey = Object.keys(clientsStore)[1];
                  const clients = clientsStore[secondKey]
                    ? Object.entries(clientsStore[secondKey]).map(([client_id, client]) => ({
                      ...client,
                      client_id,  // Add client_id to the client object
                    }))
                    : [];

                  return clients.length > 0 ? (
                    clients.map(client => (
                      <div
                        key={client.client_id}
                        className={`rounded-md h-[34px] w-[98.5%] text-white text-[15px] my-1 flex items-center px-2.5 border-3 ${currentClient?.organization_name ? (currentClient.client_id === client.client_id? 'bg-[#784DBD] border-[#DBC5FF] border-[3px]' : 'bg-[#380093] border-[#5406D2] border-[3px]') : 'bg-[#380093] border-[#5406D2] border-[3px]'}`}
                        onClick={() => {
                          setCurrentClient({...client, client_id: client.client_id});
                          localStorage.setItem('LastClient', client.client_id);
                        }}
                      >
                        <p>
                          {isSmallScreen
                            ? client.organization_name.length > 10
                              ? `${client.organization_name.slice(0, 5)}...`
                              : client.organization_name
                            : isMediumScreen
                              ? client.organization_name.length > 12
                                ? `${client.organization_name.slice(0, 10)}...`
                                : client.organization_name
                              : isLargeScreen
                                ? client.organization_name.length > 25
                                  ? `${client.organization_name.slice(0, 15)}...`
                                  : client.organization_name
                                : isXLargeScreen
                                  ? client.organization_name.length > 40
                                    ? `${client.organization_name.slice(0, 20)}...`
                                    : client.organization_name
                                  : client.organization_name}
                        </p>
                      </div>
                    ))
                  ) : (
                    <div className="flex flex-col items-center justify-center text-white">
                      {clientNames.map((clientName, index) => (
                        <div
                          key={index}
                          className="rounded-md h-[34px] w-[98.5%] text-white text-[15px] my-0.5 flex items-center px-2.5 border-3 bg-[#380093] border-[#5406D2] border-[3px]"
                          onClick={() => {
                            setCurrentClient({ organization_name: clientName, client_id: clientName });
                            localStorage.setItem('LastClient', clientName);
                          }}
                        >
                          <p>{clientName}</p>
                        </div>
                      ))}
                    </div>
                  );
                })()
              ) : (
                <p>{""}</p>
              )}
            </div>
          </div>

        </div>
        <div className="w-4/5 ml-3 bg-[#11002e] ">
          <div className=" mb-4 mt-3  lg:flex md:flex items-center justify-between ">
            <div className="flex justify-start">
              <button className=" rounded-md mx-2 text-sm lg:w-[260px]  md:w-[200px]  w-[150px]  relative bottom-0 md:bottom-0 sm:bottom-0 sm:top-[.20] h-[45px] border-[3px] border-[#A85101] bg-[#813E00] text-white ">Show Due Proposals</button>
              {/* <input
                type="text"
                className="  lg:w-[330px]  md:w-[200px]  w-[150px] lg:p-2 md:p-2 pt-2 pb-2  relative bottom-0 text-white border-[3px] border-[#5406D2] bg-[#250062]  placeholder:text-gray-300 focus:outline-none focus:ring-2 focus:ring-[#5406D2]"
                placeholder="Search"
              /> */}
            </div>
            <div className=" mt-2 flex justify-end lg:bottom-0 md:bottom-0 top-4 absolute right-6">
              {/* user collab */}
              <Collaborators headerText={"User Collaborator"} type={"User_Collaborator"} />
            </div>
          </div>

          <div className="inner-section mb-4 flex justify-between">
            <p className="font-thin">Your proposals are in order by due date { }</p>
            <div className="font-thin relative  bottom-1">
              {/* client collab */}
              {
                currentClient && currentClient.organization_name ? (
                  <Collaborators headerText={"Client Collaborator"} type={"Client_Collaborator"} currentClient={currentClient} />
                ) : null
              }

            </div>
          </div>
          <div className="flex w-full mt-1">
            <div className="dash p-1 d-flex items-center gap-3 bg-red-400 ">
              <WrapperModalPlan>
                <div className="dashboard-box flex flex-col items-center justify-center " onClick={() => handleNew()}>
                  <AddCircleOutlineIcon />
                  <div className="category2 text-lg">New Proposal Dashboard</div>
                  <div className="category4 text-lg">Select a client to add a proposal to</div>
                  <div className="category4 text-lg">Access templates or create a new proposal</div>
                </div>
              </WrapperModalPlan>
              {currentClient && currentClient.proposals && currentClient.proposals.length > 0 ? (
                <>
                  {currentClient.proposals.map((item, index) => (
                    <ProposalCard
                      key={index}
                      item={item}
                      index={index}
                      handleClick={() => handleClick(item)}
                    />
                  ))}
                </>
              ) : (
                <h1><KeyboardDoubleArrowLeftIcon /> Click on New Proposal Dashboard to create your first Proposal!</h1>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Main;
import React, { useState } from "react";
import { Link } from "react-router-dom";
import emailjs from "emailjs-com";
import Header from "../../Components/Header";
import PricingPlans from "../../Components/Pricingplans";
import Footer from "../../Components/Footer";
function LandingPage() {
  const [email, setEmail] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  /*function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }*/ 
  const handleSubmit = (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      alert('Please enter a valid email address.');
      return;
    }

    var templateParams = {
      email: email,
    };

    emailjs
      .send(
        "service_01z7a4e",
        "template_6qa73z6",
        templateParams,
        "bLmeEmaltQUSTuoHm"
      )
      .then(
        (result) => {
          setEmail("");
          setIsFormSubmitted(true);
          setIsEmailSent(true);
        },
        (error) => {
          alert("Joining the waitlist failed.");
        }
      );
  };

  const headerLinks = [
    {
      url: "/form",
      name: "profile-btn",
      value: "Profiles",
    },
    {
      url: "/grant-assistant",
      name: "grant-btnn",
      value: "Grants",
    },
    {
      url: "/signup",
      name: "sign up",
      value: "Sign Up",
    },
    {
      url: "/login",
      name: "sign in",
      value: "Sign in",
    },
  ];

  return (
    <div>
      <Header />
      <div className="pre-launch-container m-auto justify-center px-4 sm:pr-3 md:pr-6 sm:pl-2 ">
        <div className="flex flex-col  lg:flex-row p-18 items-center sm:gap-3 md:gap-4 lg:gap-28 sm:pt-3 lg:ml-16 min-[320px]:gap-6">
          <div className="flex flex-col min-[320px]:pt-6 sm:pt-5 md:pt-2 sm:justify-center sm:items-start md:items-start lg:items-start lg:ml-6 md:ml-5">
            <h3 className="min-[350px]:text-3xl sm:text-6xl md:text-8xl lg:text-8xl font-bold ">
              <h1 className="heroText text-white lg:text-[7rem]">
                Grant<span className="text-violet-700">AI</span>de
                <br />
              </h1>
              <span className="font-normal text-white">
                Automated
                <br />
                grant writing
              </span>
            </h3>
          </div>
          <div className=" sm:pt-0 md:pt-5 lg:pt-20">
            {/* Rene */}

            <div className="video-container">
              <video width="860" height="500" controls>
                <source
                  src="https://grantaide-video-files.s3.us-east-2.amazonaws.com/GrantAIde+Movie+1.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="flex flex-row pt-5 min-[320px]:pt-3">
              {/* <img
                src="assets/jennifer-image.svg"
                alt="reviewer"
                className=" min-[320px]:w-9 sm:w-10 md:w-12 lg:w-16"
              />
              <p className="min-[320px]:text-xs sm:text-xs md:text-sm lg:text-lg">
                “GrantAIde made my life so much easier” <br />
                <p className="px-2 sm:text-xs md:text-sm lg:text-lg">
                  Jennifer Emmerson 
                </p>
              </p> */}
              <div>
                <h3 className="font-bold min-[320px]:text-xl md:text-xl lg:text-2xl sm:py-2 lg:py-4 lg:pl-[6px] text-white">
                  <span className="text-violet-700">AI</span> Generated Grant
                  Proposals. Write more grants in less time & effort
                </h3>
                <p className="text-white min-[320px]:text-xs sm:text-sm md:text-base lg:text-xl">
                  Join the waiting list for early exclusive access to GrantAIde
                </p>
              </div>
            </div>
          </div>
        </div>

        <form
          action="https://grantaide.us14.list-manage.com/subscribe/post?u=3b794758f4db2f77d88bb4c13&amp;id=4c78aff213&amp;f_id=001182e3f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
          onSubmit={handleSubmit}
        >
          <div className="py-8 flex flex-col sm:flex-row justify-center items-center">
            <input
              type="email"
              value={email}
              name="EMAIL"
              placeholder="Enter your email..."
              className="border ring-[#C4C4C4] ring-1 rounded-xl px-3 py-2 text-base w-40 sm:w-52 lg:w-72"
              id="mce-EMAIL"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
            <input
              type="submit"
              value="Join Our Waitlist"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="mt-2 sm:mt-0 sm:ml-3 border border-none rounded-xl px-3 py-2 text-base bg-[#00BD13] text-white w-40 sm:w-44 lg:w-56"
              disabled={isFormSubmitted}
            />
            <div>
              {isEmailSent && (
                <p className="success-message">Email sent successfully!</p>
              )}
            </div>
          </div>
          <div id="mce-responses" className="clear foot">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: "none" }}
            ></div>
            <div
              className="response"
              id="mce-success-response"
              style={{ display: "none" }}
            ></div>
          </div>
          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_3b794758f4db2f77d88bb4c13_4c78aff213"
              tabIndex="-1"
              value=""
            />
          </div>
        </form>

        <div className="flex flex-col items-center pt-14 min-[320px]:pt-10">
          <h3 className="text-white justify-center font-black leading-17 sm:text-2xl md:text-5xl lg:text-6xl">
            Fast & Easy Grant Writing{" "}
          </h3>
          <p className="text-white leading-6 font-normal  pt-8 min-[320px]:text-xs sm:text-sm md:text-lg lg:text-xl ">
            GrantAIde generates grant proposals tailored to your needs
            effortlessly
            <br /> and in less time. Making the grant writing process fast, and
            easy.
            <h4 className="text-white font-black leading-8 pt-8 min-[320px]:text-xs sm:text-sm md:text-lg lg:text-xl">
              Proven efficiency.
            </h4>
            <p className="text-white pt-4 pb-8 sm:text-sm md:text-lg lg:text-xl">
              Write more grants tailored to your needs in less time and with
              less using
              <br /> GrantAIde’s powerful and efficient grant writing AI
              technology.{" "}
            </p>
          </p>

          <div className=" bg-[#6C83FF] text-white h-22 rounded-lg border-2 py-4  md:w-80 lg:w-[650px] px-4 lg:px-8">
            <p className=" font-black min-[320px]:text-xs sm:text-sm md:text-lg lg:text-xl leading-8 min-[320px]:m-0  min-[320px]:p-3 text-center">
              “GrantAIde saved my business by writing more winning grants,
              quickly and effortlessly.”{" "}
            </p>
            <div className=" flex flex-col justify-center items-center">
              <img src="assets/irene-image.svg" alt="Irene Jackson" />
              <p className=" min-[320px]:text-xs md:text-sm lg:text-base">
                Irene Jackson
              </p>
            </div>
          </div>
          <p className="text-white min-[320px]:text-xs sm:text-sm md:text-base lg:text-xl">
            <br />
            Join the waiting list for early exclusive access to GrantAIde
          </p>
          <form
            action="https://grantaide.us14.list-manage.com/subscribe/post?u=3b794758f4db2f77d88bb4c13&amp;id=4c78aff213&amp;f_id=001182e3f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
            onSubmit={handleSubmit}
          >
            <div className="py-8 flex flex-col sm:flex-row justify-center items-center">
              <input
                type="email"
                value={email}
                name="EMAIL"
                placeholder="Enter your email..."
                className="border ring-[#C4C4C4] ring-1 rounded-xl px-3 py-2 text-base w-40 sm:w-52 lg:w-72"
                id="mce-EMAIL"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
              <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
              <input
                type="submit"
                value="Join Our Waitlist"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="mt-2 sm:mt-0 sm:ml-3 border border-none rounded-xl px-3 py-2 text-base bg-[#00BD13] text-white w-40 sm:w-44 lg:w-56"
                disabled={isFormSubmitted}
              />
              <div>
                {isEmailSent && (
                  <p className="success-message">Email sent successfully!</p>
                )}
              </div>
            </div>
            <div id="mce-responses" className="clear foot">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: "none" }}
              ></div>
              <div
                className="response"
                id="mce-success-response"
                style={{ display: "none" }}
              ></div>
            </div>
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_3b794758f4db2f77d88bb4c13_4c78aff213"
                tabIndex="-1"
                value=""
              />
            </div>
          </form>
        </div>

        <div className="flex flex-col justify-center items-center">
          <h1 className="text-white font-black lg:text-5xl leading-10 sm:text-xl md:text-2xl">
            Revolutionizing Grant Writing
          </h1>
          <p className="text-white min-[320px]:pt-0 lg:pt-3  min-[320px]:text-[11px] sm:text-sm lg:text-base">
            GrantAIde generates more grant proposals in less time effortlessly
          </p>
          {/* TO UNDO Rene */}
          <PricingPlans />
          {/*video/gif will go here later
          <div className="video-container mt-6">
            <video width="800" height="450" controls>
              <source
                src="https://grantaide-video-files.s3.us-east-2.amazonaws.com/GrantAIde+Movie+1.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          {/* <img
            src="/assets/Large_Demo_video.svg"
            alt="large demo pic"
            className="min-[320px]:pt-5 sm:pt-8 lg:pt-10 min-[320px]:w-80 md:w-[500px] lg:w-[756px]"
          /> */}
        </div>
      </div>
      <div className="mt-6">
        <Footer />
      </div>
    </div>
  );
}

export default LandingPage;

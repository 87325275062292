import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import CircularProgress from '@mui/material/CircularProgress';
// Radio button imports
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

/***************************************
 * library and worker to read pdf files
 */
import * as pdfjsLib from "pdfjs-dist/legacy/build/pdf";
import { getDocument } from "pdfjs-dist/legacy/build/pdf";
import parseGrantProposalText from "../utils/parseGrantProposalText";
const pdfjsWorker = require("pdfjs-dist/legacy/build/pdf.worker.entry");
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
// *************************************

const baseurl = process.env.REACT_APP_API_URL;

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  width: 1,
});

const UploadButton = styled(Button)({
  height: "40px",
  color: "white",
  display: "flex",
  alignItems: "center",
  backgroundColor: "#380093",
  border: "solid 3px #5406D2",
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "black",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const customStyle = {
  backgroundColor: "#18003F",
  color: "white",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#1B4595",
    },
    "&:hover fieldset": {
      borderColor: "#1B4595",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#1B4595",
    },
  },
  "& .MuiInputLabel-root": {
    color: "white",
  },
  "& .MuiSelect-icon": {
    color: "white",
  },
  "& .MuiInputBase-input": {
    color: "white",
  },
};

const FileUploader = (props) => {
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [open, setOpen] = useState(false);
  const [documentType, setDocumentType] = useState("grantproposal");
  const [documentDescription, setDocumentDescription] = useState("");
  const { from,uid, sendDataToParent, grantProposalForm_id, client_Organization_id } = props;

  useEffect(() => {
    if (props.from === "grantaide") {
      setOpen(true);
    } else {
      setOpen(false);
    }
    const fetchUploadedDocuments = async () => {
      try {
        const response = await fetch(`${baseurl}/gptRun/get-documents`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            uid,
            grantProposalForm_id,
            client_Organization_id
          })
        });

        const data = await response.json();
        if (response.ok) {
          const filesWithProperty = data.documents.map(file => ({ name: file }));
          setUploadedFiles(filesWithProperty);
        } else {
          console.error("Failed to fetch documents: ", data);
        }
      } catch (error) {
        console.log("Error fetching uploaded files: ", error);
      }
    };

    fetchUploadedDocuments();
  }, [uid, grantProposalForm_id, client_Organization_id]);

  const uploadToServer = async (files) => {
    const formData = new FormData();
    Array.from(files).forEach((file) => {
      formData.append("file", file);
    });
    formData.append("uid", uid);
    formData.append("grantProposalForm_id", grantProposalForm_id);
    formData.append("client_Organization_id", client_Organization_id);

    try {
      const response = await fetch(`${baseurl}/gptRun/upload`, {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (response.ok) {
        console.log("File uploaded successfully: ", data);
      } else {
        console.error("Failed to upload file: ", data);
      }
    } catch (err) {
      console.error("Error uploading file: ", err);
    }
  }

  const handleClose = () => setOpen(false);

  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (files.length === 0) return;

    setUploadedFiles((currentFiles) => [...currentFiles, ...files]);

    const reader = new FileReader();
    const file = files[0];
    if (file.type === "application/pdf") {
      uploadToServer(files);
      reader.onload = async (e) => {
        const typedArray = new Uint8Array(e.target.result);
        try {
          const pdfDoc = await getDocument(typedArray).promise;
          const textContentAllPages = [];

          const numPages = pdfDoc.numPages;
          for (let pageNum = 1; pageNum <= numPages; pageNum++) {
            const page = await pdfDoc.getPage(pageNum);
            const textContent = await page.getTextContent();
            const textItems = textContent.items
              .map((item) => item.str)
              .filter((line) => line.trim() !== "");
            textContentAllPages.push(...textItems);
          }

          const allText = textContentAllPages.join(" ");
          setLoading(true);
          let response = await parseGrantProposalText(allText);
          sendDataToParent(JSON.parse(response));
          if (!response) {
            throw new Error("Could not extract grant proposal text");
          }
        } catch (error) {
          console.log("Error reading PDF:", error);
        } finally {
          setLoading(false);
        }
      };
    }
    reader.readAsArrayBuffer(file);
  };

  const handleAddFiles = () => {
    document.getElementById("upload-button").click();
    setOpen(false)
  };

  const handleDocumentTypeChange = (event) => {
    setDocumentType(event.target.value);
  };

  const handleDocumentDescriptionChange = (event) => {
    setDocumentDescription(event.target.value);
  };

  return (
    <div className="sidemenu w-auto h-5/6 mt-6 rounded-md mx-2">
      <UploadButton
        type="button"
        onClick={() => setOpen(true)} // Open modal on click
        variant="contained"
        component="span"
      >
        <div className="upload-btn-divs text-sm sm:w-auto lg:w-[150px]">Upload Files</div>
        <AddCircleOutlineIcon />
      </UploadButton>

      <VisuallyHiddenInput
        id="upload-button"
        type="file"
        multiple
        accept=".docx,.pdf,.txt"
        onChange={handleFileChange}
      />

      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '15%',
          }}
        >
          <CircularProgress
            sx={{
              width: '30px !important',
              height: '30px !important'
            }}
          />
        </Box>
      )}

      {uploadedFiles.map((file, index) => (
        <div key={index} className="uploaded-file ">
          <InsertDriveFileOutlinedIcon />
          {file.name.length > 20 ? `${file.name.slice(0, 20)}...` : file.name}
        </div>
      ))}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            sx={{ display: "flex", justifyContent: "center" }}
            id="modal-title"
            variant="h6"
            component="h2"
            color="white"
          >
            Document Upload
          </Typography>
          <FormControl fullWidth sx={{ mt: 2, paddingX: 2, ...customStyle }}>
            <RadioGroup
              aria-labelledby="document-type-label"
              value={documentType}
              onChange={handleDocumentTypeChange}
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="grantproposal"
                control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />}
                label="Grant Proposal"
              />
              <FormControlLabel
                value="grantqna"
                control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />}
                label="(RFP) Request for Proposal"
              />
              <FormControlLabel
                value="grantNofo"
                control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />}
                label="(NOFO) Notice of Funding Opportunity"
              />
              <FormControlLabel
                value="grantFinancialDoc"
                control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />}
                label="Financial Document"
              />
              <FormControlLabel
                value="other"
                control={<Radio sx={{ color: 'white', '&.Mui-checked': { color: 'white' } }} />}
                label="Other"
              />
            </RadioGroup>
          </FormControl>
          <TextField
            id="document-description"
            label="Document Description"
            multiline
            rows={4}
            value={documentDescription}
            onChange={handleDocumentDescriptionChange}
            variant="outlined"
            fullWidth
            sx={{ mt: 2, ...customStyle }}
          />
          <div className="file-upload-btns">
            <Button
              onClick={handleAddFiles}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Add files
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default FileUploader;